import axios from 'axios';
import genkeys from 'rezauthlib';
import { BRANDING_API } from '../../config/api';

const state = {
	branding: {},
};

const getters = {
	getBranding: (state) => state.branding,
};

const actions = {
	setBranding({ commit }, slug) {
		const SEC_DATA = genkeys('9156ce81c8d8af8e1bc52250f4cf663e', '3e7c73027ec6e43cc29e9e3748b250f09a15993ae53fa293');
		const BRANDING_API_FINAL = BRANDING_API.replace('#slug#', slug);
		return axios
			.post(BRANDING_API_FINAL, '', {
				headers: {
					b1: SEC_DATA.b1s,
					v2: SEC_DATA.v2s,
					c2s: SEC_DATA.c2s,
					a1: SEC_DATA.a1,
					v1: SEC_DATA.v1,
					c1: SEC_DATA.uidgen,
					'Content-Type': 'application/json',
				},
			})
			.then(
				(response) => {
					let dataSent = JSON.parse(response.request.response);
					if (dataSent.code == '1') {
						commit('setNewBranding', dataSent);
						return dataSent;
					} else {
						console.log(dataSent.msg);
					}
				},
				(error) => {
					console.log(error);
				}
			);
	},
};

const mutations = {
	setNewBranding: (state, branding) => (state.branding = branding),
};

export default {
	state,
	getters,
	actions,
	mutations,
};
