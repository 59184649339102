<template>
  <div class="basket-icon-wrapper">
    <span class="basket-number">{{ basket_number }}</span>
    <svg
      version="1.1"
      id="ID-basket-icon"
      class="basket-icon"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 39.7 33.9"
      style="enable-background: new 0 0 39.7 33.9"
      xml:space="preserve"
    >
      <g id="Group_21465" transform="translate(-945 -361.422)">
        <g id="Group_800">
          <g id="Group_798">
            <path
              id="Path_708"
              class="ID-basket-icon-fill"
              d="M977.9,388.5H961l-8-15.7h-6c-1.1,0-2-0.9-2-2s0.9-2,2-2h8.4l8,15.7h12.1l5.4-10.5
                c0.5-1,1.7-1.4,2.7-0.9c1,0.5,1.4,1.7,0.9,2.7L977.9,388.5z"
            ></path>
          </g>
          <g id="Group_799">
            <circle
              id="Ellipse_25"
              class="ID-basket-icon-fill"
              cx="962.4"
              cy="392.9"
              r="2.4"
            ></circle>
            <path
              id="Path_16874"
              class="ID-basket-icon-fill"
              d="M975.4,390.5c1.3,0,2.4,1.1,2.4,2.4c0,1.3-1.1,2.4-2.4,2.4c-1.3,0-2.4-1.1-2.4-2.4
                C973,391.6,974.1,390.5,975.4,390.5C975.4,390.5,975.4,390.5,975.4,390.5z"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>
<script>
export default {
  name: "BasketIcan",
  props: {
    basket_number: undefined,
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/variables.scss";
.basket-icon-wrapper {
  position: relative;
  width: 37px;
  height: 38px;
  margin-left: 3px;
}
.basket-icon {
  width: 37px;
  height: 38px;
  margin-left: -7px;
  box-sizing: border-box;
}
.ID-basket-icon-fill {
  fill: $header-basket-icon;
}
.basket-number {
  position: absolute;
  left: 0px;
  right: 0px;
  text-align: center;
  top: 0px;
  font-weight: bold;
  font-size: 16px;
  color: $header-basket-number;
}
</style>




