var $bc1 = `#2475a7`,
    $bc1_2 = `#2475a7`,
    $bc2 = `#1A2A40`,
    $bc2_2 = `#152233`,
    $bc3 = `#DEA602`,
    $bc4 = `#E30613`;

exports.cssData = [

  // header
  {
    selector: `.header-wrapper`,
    styles: `background: red; border-bottom: 3px solid ${$bc1_2};`
  },
  {
    selector: `.header-wrapper .menu-items-wrapper`,
    styles: `color: ${$bc2};`
  },
  {
    selector: `.header-wrapper .menu-items-wrapper .menu-item`,
    styles: `border-left: 0px solid #ddd;`
  },
  {
    selector: `.header-wrapper .menu-items-wrapper .menu-item:hover`,
    styles: `border-left: 0px solid #ddd; background: #e9f1f6;`
  },
  {
    selector: `.header-wrapper .menu-items-wrapper .menu-item:first-child`,
    styles: `border-left: 0px solid #ddd;`
  },
  {
    selector: `.header-wrapper .menu-items-wrapper .menu-item .basket-number`,
    styles: `color: #E30613;`
  },
  {
    selector: `.header-wrapper .menu-items-wrapper .menu-item .lang-text`,
    styles: ` background: #000; color: #fff;`
  },
  {
    selector: `.header-wrapper .menu-items-wrapper .menu-item .menu-items_mobile-wrapper`,
    styles: ` background: #fff;`
  },

  // footer
  {
    selector: `.footer-wrapper-top`,
    styles: `background: ${$bc2};  border-top: 1px solid #ddd;`
  },
  {
    selector: `.footer-inner-top .footer-links a`,
    styles: `color: #fff;`
  },
  {
    selector: `.footer-wrapper-bottom`,
    styles: `background: ${$bc2_2}; border-top: 1px solid #fff;`
  },
  {
    selector: `.footer-inner-bottom`,
    styles: `color: #fff;`
  },

  // loading
  {
    selector: `.widget-loading span`,
    styles: `color: #fff;`
  },
  {
    media: `min-width: 567px`,
    selector: `.widget-loading span`,
    styles: `color: ${$bc2};`
  },

  {
    selector: `.loader`,
    styles: `border-top: 8px solid rgba(255,255,255,0.3); border-right: 8px solid rgba(255,255,255,1);border-bottom: 8px solid rgba(255,255,255,1); border-left: 8px solid rgba(255,255,255,1);`
  },
  {
    media: `min-width: 567px`,
    selector: `.loader`,
    styles: `border-top: 8px solid rgba(255,255,255,0.3); border-right: 8px solid ${$bc2};border-bottom: 8px solid  ${$bc2}; border-left: 8px solid  ${$bc2};`
  },


  // individual pages

  // indexpage
  {
    selector: `.hero-section .hero-text`,
    styles: `color: #fff;`
  },
  {
    selector: `.hero-section .hero-text h1`,
    styles: ``
  },

];