<template>
  <div
    class="header-wrapper"
    @mouseleave="leaveUserPanel()"
    :class="`${manage_header_no_toggle ? ` manage-header-perm` : ``}`"
  >
    <div class="header-inner">

      <template v-if="getSlug() === 'SV1'">
        <div @click="gotoPage('https://summitairportparking.com', 'external')" class="ID-logo"></div>
      </template>
      <template v-else>
        <div v-if="loggedIn" @click="gotoPage('/dashboard.html')" class="ID-logo"></div>
        <div v-else @click="getSlug() === 'PV1' ? gotoPage(logo_url, 'external') : gotoPage(logo_url)" class="ID-logo"></div>
      </template>

      <div class="menu-items-wrapper">
        <div
          v-if="loggedIn"
          @click="gotoPage(home_link)"
          class="menu-item tab-home"
        >
          <!-- {{ "Logout" }} -->
          <!-- <i class="fa fa-home" aria-hidden="true"></i> -->
        </div>

        <template v-for="(item, index) in header_items" :key="index">
          <div
            v-if="logged_in && item.loggedin"
            :class="`menu-item menu-item-loop tab-${index + 1}`"
            @click="gotoPage(item.url)"
          >
            {{ item.text }}
          </div>
          <div
            v-if="!logged_in && item.loggedout && item.text == 'Login'"
            :class="`menu-item menu-item-loop tab-${index + 1}`"
            @click="openBasic"
          >
            {{ item.text }}
          </div>
          <div
            v-if="!logged_in && item.loggedout && item.text != 'Login'"
            :class="`menu-item menu-item-loop tab-${index + 1}`"
            @click="gotoPage(item.url)"
          >
            {{ item.text }}
          </div>
        </template>

        <div
          v-if="show_languages"
          @click="show_language_dropdown = !show_language_dropdown"
          class="menu-item menu-item-dropdown-lang tab-lang"
        >
          <span class="lang-text">{{ lang }}</span>
          <div class="lang-flag" :style="flagImage(lang)"></div>
          <transition name="fade">
            <div
              v-show="show_language_dropdown"
              class="menu-item-dropdown-lang__select"
            >
              <div
                v-for="lng in languages"
                :key="lng"
                class="menu-item-dropdown-lang__select-item"
                @click="changeLang(lng)"
              >
                <div :class="`lang-flag ${lng}`" :style="flagImage(lng)"></div>
                <span>{{ lng.toUpperCase() }}</span>
              </div>
            </div>
          </transition>
        </div>

        <div
          v-if="show_basket"
          class="menu-item tab-basket"
          @click="toggleBasket('toggle')"
          tabindex="0"
        >
          <BasketIcon :basket_number="basket_number" />
          <span class="basket-text">Basket</span>
        </div>

        <!-- <div
          :class="`${manage_header ? `manage-header-show` : ``}`"
          class="user-panel"
          v-if="logged_in"
          @mouseenter="enterUserPanel()"
          @click="toggleUserPanel()"
        >
          <img src="@/assets/images/user.png" alt="user image" />
          <span>Welcome {{ userfirstname }}</span>
        </div> -->

        <div v-if="loggedIn" class="user-panel">
          <div v-if="initials" class="user-initials">{{ initials }}</div>
          <span v-if="firstname || surname" class="user-fullname"
            >{{ firstname }} {{ surname }}</span
          >
          <span class="user-logout" @click="gotoPage(home_link)"
            ><i class="fas fa-sign-out-alt"></i> {{ logoutText }}</span
          >
        </div>

        <div
          v-if="header_items.length"
          class="menu-item tab-mobile-menu"
          @click="showMobileMenu = !showMobileMenu"
          @focusout="showMobileMenu = false"
          tabindex="0"
        >
          <i class="fa fa-bars"></i>
          <transition name="fade">
            <div v-show="showMobileMenu" class="menu-items_mobile-wrapper">
              <template v-for="(item, index) in header_items" :key="index">
                <div
                  v-if="logged_in && item.loggedin"
                  :class="`menu-item-mobile tab-${index + 1}`"
                  @click="gotoPage(item.url)"
                >
                  <span>{{ item.text }}</span
                  ><i class="fa fa-chevron-right"></i>
                </div>

                <div
                  v-if="!logged_in && item.loggedout"
                  :class="`menu-item-mobile tab-${index + 1}`"
                  @click="gotoPage(item.url)"
                >
                  <span>{{ item.text }}</span
                  ><i class="fa fa-chevron-right"></i>
                </div>
              </template>

              <template v-for="(item, index) in manage_menu" :key="index">
                <div
                  v-if="logged_in"
                  :class="`menu-item-mobile tab-${index + 1}-2`"
                  @click="gotoPage(item.url)"
                >
                  <span>{{ item.text }}</span
                  ><i class="fa fa-chevron-right"></i>
                </div>
              </template>

              <div
                v-if="logged_in"
                @click="logged_in = !logged_in"
                :class="`menu-item-mobile tab-logout`"
              >
                <span>{{logoutText}}</span><i class="fa fa-chevron-right"></i>
              </div>

              <div class="menu-item-mobile tab-lang" v-if="show_languages">
                <div v-for="lng in languages" :key="lng">
                  <div
                    v-if="lng !== params.lang"
                    class="lang-flag"
                    :style="getFlagImage(lng)"
                    @click="changeLang(lng)"
                  ></div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <div
      v-if="logged_in"
      :class="`manage-header${manage_header ? ` manage-header-show` : ``}`"
    >
      <div class="manage-header-inner">
        <a
          v-for="(item, index) in manage_menu"
          :key="index"
          :href="item.url"
          :class="`${item.active === manage_active ? 'active' : ''}`"
          >{{ item.text }}</a
        >
        <a v-if="logged_in" @click="logged_in = !logged_in">{{logoutText}}</a>
      </div>
    </div>
  </div>
  <Dialog
    header="Please enter your email id to proceed"
    v-model:visible="dialog"
    :style="{ width: '50vw' }"
  >
    <div class="block">
      <label for="emailid">
        {{ "Enter your email address" }}
        <span class="form-mandatory">*</span>
      </label>
      <input
        name="emailid"
        autofocus="autofocus"
        type="text"
        id="emailid"
        data-lpignore="true"
      />
    </div>
    <template #footer>
      <button @click="closeBasic">{{ "Close" }}</button>
      <button @click="closeBasic" autofocus>{{ "Proceed" }}</button>
    </template>
  </Dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BasketIcon from "@/components/BasketIcon.vue";

import md5 from "js-md5";
const localforage = require("localforage");
let store = localforage.createInstance({
  name: "tranlations",
});

export default {
  name: "Header",
  props: {
    logo_link: undefined,
    home_link: undefined,
    lang: undefined,
    show_home: undefined,
    show_languages: undefined,
    show_basket: undefined,
    basket_number: undefined,
    manage_header_no_toggle: undefined,
    manage_active: undefined,
    userfirstname: undefined,
    loggedIn: undefined,
    brandSlug: undefined,
    logoutText: undefined,
  },
  components: {
    BasketIcon,
  },
  data() {
    return {
      logo_url: "",
      firstname: "",
      surname: "",
      initials: "",
      show_language_dropdown: false,
      logged_in: false,
      dialog: false,
      manage_header: false,
      showMobileMenu: false,
      showBasket: false,
      showBasketOnHeader: true,
      header_items: [],
      manage_menu: [
        { url: "/bookings.html", text: "Your Bookings", active: "bookings" },
        { url: "/details.html", text: "Your Details", active: "details" },
        {
          url: "/changepassword.html",
          text: "Change Password",
          active: "changepassword",
        },
      ],
      languages: ["EL", "EN"],
    };
  },
  computed: {
    // flagImage() {
    //   return `background-image: url(${require(`../assets/flags/` +
    //     this.lang +
    //     `.png`)});`;
    // },
  },
  methods: {
    ...mapActions(["setSlug", "setBranding"]),
    ...mapGetters(["getSlug", "getBranding", "getSiteName"]),
    getrans(string) {
      var s = md5(string);
      var ss = this.brandSlug + "-" + this.lang.toUpperCase() + "-" + s;
      //console.log(ss);
      return this.translations[ss] || string;
    },
    changeLang(lang) {
      store.setItem("tranlations", null);
      this.setSessionData("language", lang);
      const url = new URL(window.location.href);
      var search_params = url.searchParams;
      search_params.set("lang", lang);
      url.search = search_params.toString();
      var new_url = url.toString();
      window.location = new_url;
    },
    flagImage(lang) {
      console.log("lang", lang);
      return `background-image: url(${require(`../assets/flags/` +
        lang.toUpperCase() +
        `.png`)});`;
    },
    setSessionData(name, value) {
      if (value != null) sessionStorage.setItem(name, JSON.stringify(value));
      else sessionStorage.removeItem(name);
    },

    openBasic() {
      this.dialog = true;
    },
    closeBasic() {
      this.dialog = false;
    },
    toggleBasket(val) {
      this.emitter.emit("toggleBasket", val);
    },
    gotoPage(url, type = null) {
      if (type && type.toLowerCase() === "external") {
        window.open(url);
      } else {
        window.location.href = url;
      }
    },
    enterUserPanel() {
      if (!this.manage_header_no_toggle) {
        this.manage_header = true;
      }
    },
    leaveUserPanel() {
      if (!this.manage_header_no_toggle) {
        this.manage_header = false;
      }
    },
    toggleUserPanel() {
      if (!this.manage_header_no_toggle) {
        this.manage_header = !this.manage_header;
      }
    },
  },
  mounted() {
    if (this.manage_header_no_toggle) {
      this.manage_header = true;
    }
    if (typeof this.userfirstname === "undefined") {
      this.logged_in = false;
    } else {
      this.logged_in = true;
    }

    console.log("this.lang");
    console.log(this.lang);

    this.firstname = window.sessionStorage.getItem("first_name");
    this.surname = window.sessionStorage.getItem("last_name");
    this.initials = window.sessionStorage.getItem("initials");

    this.logo_url = this.logo_link;
    if (this.getSlug() === "PV1") {
      this.logo_url = "https://pvdairpark.com/";
    }
    if (this.getSlug() === "PIT") {
      this.logo_url = "https://flypittsburgh.com/pittsburgh-international-airport/parking/parking-reservations";
    }
  },
  updated() {
    if (this.getSlug() === "AIR") {
      this.languages = ["en", "el", "es", "de", "fr", "pt"];
      //console.log(this.brandSlug, this.languages);
    }
    if (this.getSlug() === "SZG") {
      this.languages = ["en", "de"];
      //console.log(this.brandSlug, this.languages);
    }
    if (this.getSlug() === "FM1") {
      this.languages = ["en", "de"];
      //console.log(this.brandSlug, this.languages);
    }
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables.scss";

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.user-panel {
  //display: none;
  display: flex;
  @media screen and (min-width: 960px) {
    padding: 15px 15px;
    display: flex;
    align-items: center;
    max-height: 56px;
    transition: 0.15s ease-in-out;
    span {
      padding-left: 0.5rem;
      font-weight: bold;
    }
  }
  .user-fullname {
    @media screen and (max-width: 1200px) {
      display: none;
    }
  }
  .user-initials {
    @media screen and (max-width: 960px) {
      display: none;
    }
    background: var(--header_background_color_active, #000);
    color: var(--header_background_color, #fff);
    width: 2rem;
    height: 2rem;
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .user-logout {
    padding-left: 2rem;
    font-weight: bold;
    cursor: pointer;
    color: var(--header_basket_number_color, #236ca3);
  }
}

.user-panel.manage-header-show {
  background: $header-user-bg-hvr;
  color: $header-user-text-hvr;
}

.manage-header.manage-header-show {
  max-height: 60px;
  box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.5);
}

.manage-header-perm .manage-header {
  &.manage-header-show {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5);
  }

  .manage-header-inner {
    a {
      align-items: center;
      display: flex;
      position: relative;
      height: 100%;
    }
    a.active:after {
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
      content: " ";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent #fff transparent;
    }
  }
}

.manage-header {
  display: none;
  @media screen and (min-width: 960px) {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5);
    transition: 0.15s ease-in-out;
    max-height: 0px;
    overflow: hidden;
    float: left;
    clear: both;
    margin-top: 0px;
    position: absolute;
    display: flex;
    justify-content: center;
    top: 60px;
    left: 0px;
    right: 0px;
    width: 100%;
    z-index: 99999;
    height: 60px;
    background: $header-manage-bg;
    color: $header-manage-text;
    .manage-header-inner {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      max-width: 1280px;
      width: 100%;
      > * {
        padding: 0px 15px;
      }
      a {
        color: $header-manage-text;
        text-decoration-line: none;
      }
    }
  }
}

.header-wrapper {
  border-bottom: var(--header_border_bottom, $header-border-bottom);
  background: var(--header_background_color, $header-background);
  margin: 0;
  padding: 0;
  height: var(--header_height_desktop, 80px);
  @media screen and (max-width: 959px) {
    height: var(--header_height_mobile);
  }

  width: 100%;
  position: relative;
  box-sizing: content-box;
  .header-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    max-width: 1280px;
    margin: 0 auto;
  }

  .ID-logo {
    background-repeat: no-repeat;
    background-size: contain;
    width: 33%;
    height: 100%;
    max-height: var(--logo_height_desktop);
    @media screen and (max-width: 959px) {
      max-height: var(--logo_height_mobile);
      width: 66%;
    }

    margin: 4px 1rem;
    box-sizing: border-box;
    position: relative;
    background-image: var(--logo-image);
    cursor: pointer;
  }
  .menu-items-wrapper {
    color: var(--header_font_color, $header-text-color);
    font-family: var(--header_font_family, $header-text-font);
    font-size: var(--header_font_size, 1rem);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: auto;
    user-select: none;
    .menu-item {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      padding: 0.5rem 1rem;
      border-radius: 2rem;
      // border-right: $header-btn-border;
      transition: 0.2s ease-in-out;

      &.tab-active {
        background: var(--header_background_color_active, #034a94);
        color: var(--header_font_color_active, #034a94);
      }

      &:hover {
        background: var(--header_background_color_hover, $header-btn-hvr-bg);
        color: var(--header_font_color_hover, #034a94);
      }
      // &:first-child {
      //   border-left: $header-btn-border;
      // }
      .fa-home {
        font-size: 20px;
      }
      .lang-text {
        border-radius: 20px;
        padding: 1px 10px;
        background: $header-lang-bg;
        color: $header-lang-text;
        font-family: $header-lang-font;
      }
      .lang-flag {
        width: 32px;
        height: 32px;
      }

      .basket-icon {
        width: 37px;
        height: 38px;
        margin-left: -5px;
      }
      .basket-text {
        display: none;

        // margin-left: 10px;
        // @media screen and (max-width: 1280px) {
        //   display: none;
        // }
      }
      .basket-number {
        position: absolute;
        left: 0px;
        width: 64px;
        text-align: center;
        top: 12px;
        font-weight: bold;
        font-size: 16px;
        color: $header-basket-number;
      }
    }
    .tab-basket {
      padding-left: 10px;
      padding-right: 10px;
      margin-left: 0px;
    }
    .tab-mobile-menu {
      display: none;
    }

    .menu-items_mobile-wrapper {
      position: absolute;
      top: calc(var(--header_height_mobile) + 3px);
      right: 0px;
      display: flex;
      flex-direction: column;
      z-index: 99999;
      background: $header-mobile-bg;
      color: $header-mobile-text;
    }

    .menu-items_mobile-wrapper {
      width: 100%;
      max-width: 320px;
      box-shadow: 0 8px 8px 1px rgba(0, 0, 0, 0.5);
      .menu-item-mobile {
        box-sizing: border-box;
        padding: 15px;
        text-align: left;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      .menu-item-mobile.tab-lang {
        padding: 8px 15px;
        justify-content: flex-start;
        display: var(--header_show_langs, flex);
        .lang-flag {
          margin-right: 15px;
        }
      }
    }

    /* header compacting */
    @media screen and (max-width: 959px) {
      .menu-item-loop {
        display: none;
      }
      .tab-mobile-menu {
        padding: 1rem;
        display: flex;
        &:focus {
          outline: none;
        }
      }
    }
  }
}

.menu-items-wrapper .menu-item.tab-home {
  // @media screen and (max-width: 959px) {
  //   display: none;
  // }
}

.menu-items-wrapper .menu-item.tab-lang {
  display: var(--header_show_langs, flex);
  // @media screen and (max-width: 959px) {
  //   display: none;
  // }
}

.menu-item-dropdown-lang {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  max-height: 40px;

  &__select {
    background: #fff;
    position: absolute;
    top: calc(100% + 0.5rem);
    z-index: 99999;
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 0.5rem;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  }
  &__select-item {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    font-weight: bold;
    color: var(--header_font_color, #384048);
  }
  &__select-item:hover {
    background: var(--header_background_color_hover,#eee);
    color: var(--header_font_color_hover,#384048);
  }
}
</style>
