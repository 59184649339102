import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import DataModule from './modules/data-module';
import SlugModule from '././modules/slug-module';
import BrandModule from './modules/brand-module';

export default createStore({
	state: {},
	mutations: {},
	actions: {},
	modules: {
		DataModule,
		SlugModule,
		BrandModule,
	},
	plugins: [createPersistedState({ storage: window.sessionStorage })],
});
