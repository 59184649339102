<template>
<transition name="fade">
<div v-show="basketVisable" id="basket-positioner">
<div id="basket" :style="`top: ${positions.top};`">
<div
id="basket-fixed"
:style="`position: ${positions.position}; ${hasItems ? '' : ''};`"
>
<div class="close-basket" @click="toggleBasket(false)">
<i class="fa fa-times" aria-hidden="true"></i>
</div>
<h3 v-if="hasItems">{{ getrans("Booking summary") }}</h3>
<h3 v-else style="display: block" class="basket-empty">
{{ getrans("Your Basket is empty") }}
</h3>
<div class="basket-item" v-for="item in basketitems" :key="item">
<b class="basket-item-type">
{{ item.product_name }}
</b>
<div class="dotted">
<span
>{{ item.data.date_selected }} {{ getrans("at") }}
{{ item.data.time_selected }}</span
><span
><b>&pound; {{ item.cost }}</b></span
>
</div>
</div>
<div class="basket-item total" v-if="hasItems">
<div class="dotted">
<span>{{ getrans("Total Price") }}:</span
><span
><b
>{{
showtotalvalue(
parseFloat(totalfee) + parseFloat(bookingfee)
)
}}
</b></span
>
</div>
</div>
<div v-if="showButton && hasItems">
<p class="basket-message">Basket message</p>
<button
class="checkout-button"
@click="addtoservercart"
>
{{ getrans("Confirm & Proceed") }}
</button>
</div>
</div>
</div>
</div>
</transition>
</template>

<script>
//   import moment from 'moment';
//   import md5 from 'js-md5';
//   require('moment/locale/de');
const axios = require("axios");
const genkeys = require("rezauthlib");
import Swal from "sweetalert2";
export default {
name: "Basket",
props: {
passedclass: undefined,
lang: String,
fmmtransobjpassed: undefined,
totalfee: undefined,
bookingfee: undefined,
hasItems: undefined,
basketitems: undefined,
positionTop: undefined,
position: undefined,
showButton: undefined,
},
data() {
return {
fmmtransobj: {},
basketitems_final: [],
setbrandid:'DSA',
params: { lang: "EN" },
basketVisable: false,
window: {
width: 0,
height: 0,
},
positions: {
position: undefined,
top: undefined,
},
};
},
methods: {
getLocalData(name) {
return localStorage.getItem(name);
},
cart_initCart() {
if (this.getLocalData("USERCART") != null) {
this.basketitems_final = JSON.parse(this.getLocalData("USERCART"));
//console.log(this.basketitems_final);
}
},
addtoservercart()
{

let uuid='';
let data='';  
this.gotoPage('/yourdetails.html');
/*uuid = this.$cookie.getCookie('guid_token');
var dd = 1;
for (var k = 0; k < this.basketitems_final.length; k++) 
{
data = 
{
"brand_id":this.setbrandid,
"date":localStorage.date_value_selected,
"guid":uuid,
"slot_id":this.basketitems_final[k].data.slot_id,
"duration":this.basketitems_final[k].data.time_selected,
"pax_id":this.basketitems_final[k].product_id
};
console.log("data",data);
let secsaa = genkeys(process.env.VUE_APP_SR_SECRETID, process.env.VUE_APP_SR_SECRETKEY);
let b1sa = secsaa.b1s;let v2sa = secsaa.v2s;let a1a = secsaa.a1;let v1a = secsaa.v1;let c2sa = secsaa.c2s;let uidgena = secsaa.uidgen;
axios.post('https://devapi.rezcomm.com/medical_screening/frontend/slots/reserve', data, 
{headers: {b1: b1sa, v2: v2sa, c2s: c2sa, a1: a1a, v1: v1a, c1: uidgena}}).then(response => {
let datasent = JSON.parse(response.request.response); 
let res = datasent.data.toString();
if(res=='Successfully blocked' || res=='Error : Same as existing booking, nothing to change')
{
//do something
dd=dd+1;
}
});
}
if(dd==this.basketitems_final.length)
{

}
*/
},
toggleBasket() {
this.emitter.emit("toggleBasket");
},
handleResize() {
this.window.width = window.innerWidth;
this.window.height = window.innerHeight;
if (this.passedclass === "active") {
if (this.window.width > 959) {
this.basketVisable = true;
} else {
this.basketVisable = false;
}
}
if (this.window.width > 959) {
this.positions.position = this.position;
this.positions.top = this.positionTop;
} else {
this.positions.position = "fixed";
this.positions.top = "0px";
}
},
showtotalvalue(totalvalue) {
let setcurrency = localStorage.getItem("setcurrency");
if (setcurrency === null) {
setcurrency = "1-GBP";
}
let setcurrency_arr = setcurrency.split("-");
let currency = setcurrency_arr[1];
let currconv = setcurrency_arr[0];
let totalfee = parseFloat(totalvalue) * parseFloat(currconv);
totalfee = totalfee.toFixed(2);
if (currency == "USD") {
return totalfee.replace(".", ",") + " £";
} else {
if (currency == "USD") {
return "$" + totalfee;
} else if (currency == "GBP") {
return "£" + totalfee;
} else if (currency == "CHF") {
return "Fr." + totalfee;
} else {
return currency + totalfee;
}
}
},
gotoPage(url) {
window.location.href = url;
},
getrans(string) {
// var s = md5(string);
// var ss = s+"-"+this.params.lang;
// console.log(ss);
// return this.fmmtransobj[ss] || string;
return string;
},
},
created() {
window.addEventListener("resize", this.handleResize);
this.handleResize();
this.params.lang = this.lang;
this.fmmtransobj = this.fmmtransobjpassed;
},
mounted() {
this.cart_initCart();
let vm = this;
if (vm.passedclass === "active" && vm.window.width > 959) {
vm.basketVisable = true;
}
this.emitter.on("toggleBasket", function (val) {
if (vm.passedclass === "active" && vm.window.width > 959) {
vm.basketVisable = true;
} else {
if (val === "toggle") {
vm.basketVisable = !vm.basketVisable;
} else {
vm.basketVisable = val;
}
setTimeout(() => {
if (!vm.hasItems) {
vm.basketVisable = false;
}
}, 2000);
}
});
},
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables.scss";

.fade-enter-active,
.fade-leave-active {
transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
opacity: 0;
}

#basket-positioner {
color: $checkout-text;
display: block;
width: 100%;
max-width: 1270px;
height: 0px;
margin: 0 auto;
// border: 1px dashed blue;
position: relative;
z-index: 999;
#basket {
position: absolute;
top: 24px;
right: 4px;
width: 412px;
}
@media screen and (max-width: 959px) {
#basket {
position: absolute;
top: 24px;
right: 10px;
left: 10px;
width: 100%;
}
}
#basket-fixed {
max-width: #{"calc(1260px / 3)"};
min-width: 412px;
max-height: 100vh;
overflow-y: auto;
position: fixed;
background: #fff;
border: 1px solid #ddd;
border-radius: 4px;
box-shadow: 0 8px 8px -4px rgba(0, 0, 0, 0.5);
@media screen and (max-width: 959px) {
left: 50%;
top: 50%;
transform: translate(-50%, -50%);
max-width: 420px;
width: #{"calc(100% - 20px)"};
max-height: calc(100% - 20px);
min-width: 300px;
}
.basket-message {
padding: 0 20px;
text-align: left;
font-family: $font-family-medium;
}

h3 {
padding: 8px 20px;
text-align: left;
font-family: $font-family-bold;
//color: #364d5a;
}

.basket-empty {
width: #{"calc(100% - 40px)"};
text-align: center;
}

.basket-item {
padding: 8px 20px;
text-align: left;
//color: #364d5a;
.basket-item-type {
margin-bottom: 8px;
display: block;
line-height: 1.4;
font-size: 15px;
font-family: $font-family-medium;
}
}

.basket-item.total {
font-size: 24px;
font-family:  $font-family-bold;
margin: 22px 0;
}
.checkout-button {
background: $checkout-button;
font-family: $font-family-bold;
font-size: 20px;
font-weight: bold;
color: #fff;
width: #{"calc(100% - 40px)"};
margin: 0 20px 20px;
padding: 22px 10px;
border: 0px solid #fff;
border-radius: 3px;
cursor: pointer;
transition: 0.2s ease-in-out;
&:hover {
background: darken($checkout-button, 10%);
}
}
}

.dotted {
overflow: hidden;
font-family:  $font-family-medium;
span:first-child {
float: left;
background: white;
padding-right: 0.2em;
}
span + span {
float: right;
background: white;
padding-left: 0.2em;
}
span:first-child:after {
float: right; /* Let the before pseudo element start from the leftmost position of each list item */
width: 0;
white-space: nowrap;
content: "...........................................................................";
color: $checkout-text;
}
}
}

.close-basket {
position: absolute;
right: 0px;
top: 0px;
i {
font-size: 18px;
position: absolute;
right: 0px;
top: 0px;
padding: 28px 25px 5px;
cursor: pointer;
}
@media screen and (min-width: 960px) {
display: none;
}
}
</style>
