// set style vars
var set_style_vars = function (styles, page) {
  if (!styles) return false;

  let styles_obj = {};

  // set favicon
  if (styles.brand_favicon !== "") {
    let favicon = document.querySelector("link[rel*='icon']") || document.createElement('link');
    favicon.type = 'image/x-icon';
    favicon.rel = 'shortcut icon';
    favicon.href = styles.brand_favicon;
    document.getElementsByTagName('head')[0].appendChild(favicon);
  }

  styles_obj["--logo-image"] = `url(${styles.asset_image_1})`;

  styles_obj["--header_height_desktop"] = styles.header_height_desktop;

  // header
  styles_obj["--header_height_desktop"] = styles.header_height_desktop;
  styles_obj["--header_height_mobile"] = styles.header_height_mobile;
  styles_obj["--logo_height_desktop"] = styles.header_logo_height;
  styles_obj["--logo_height_mobile"] = styles.header_logo_height_mobile;
  styles_obj["--header_background_color"] = styles.header_background_color;
  styles_obj["--header_font_color"] = styles.header_font_color;
  styles_obj["--header_font_family"] = styles.header_font_family;
  styles_obj["--header_font_size"] = styles.header_font_size;
  styles_obj["--header_background_color_active"] = styles.header_background_color_active;
  styles_obj["--header_font_color_active"] = styles.header_font_color_active;
  styles_obj["--header_background_color_hover"] = styles.header_background_color_hover;
  styles_obj["--header_font_color_hover"] = styles.header_font_color_hover;
  styles_obj["--header_border_bottom"] = styles.header_border_bottom;
  styles_obj["--header_basket_number_color"] = styles.header_basket_number_color;
  // banner
  styles_obj["--header_banner_textcolor"] = styles.header_banner_textcolor;
  styles_obj["--header_banner_background"] = styles.header_banner_background;
  styles_obj["--header_banner_search_filter_color"] = styles.header_banner_search_filter_color;
  styles_obj["--header_show_langs"] = styles.header_show_langs;

  // hero
  styles_obj["--hero_position"] = styles.hero_position;
  styles_obj["--hero_position_y"] = styles.hero_position_y;
  styles_obj["--hero_overlay"] = styles.hero_overlay;

  // footer
  // top
  styles_obj["--footer_top_background_color"] = styles.footer_top_background_color;
  styles_obj["--footer_top_border_top_color"] = styles.footer_top_border_top_color;
  styles_obj["--footer_top_font_family"] = styles.footer_top_font_family;
  styles_obj["--footer_top_font_size"] = styles.footer_top_font_size;
  styles_obj["--footer_top_font_weight"] = styles.footer_top_font_weight;
  styles_obj["--footer_top_text_color"] = styles.footer_top_text_color;
  styles_obj["--footer_top_text_color_hover"] = styles.footer_top_text_color_hover;
  // bottom
  styles_obj["--footer_bottom_background_color"] = styles.footer_bottom_background_color;
  styles_obj["--footer_bottom_border_top_color"] = styles.footer_bottom_border_top_color;
  styles_obj["--footer_bottom_font_family"] = styles.footer_bottom_font_family;
  styles_obj["--footer_bottom_font_size"] = styles.footer_bottom_font_size;
  styles_obj["--footer_bottom_font_weight"] = styles.footer_bottom_font_weight;
  styles_obj["--footer_bottom_text_color"] = styles.footer_bottom_text_color;
  styles_obj["--footer_bottom_text_color_hover"] = styles.footer_bottom_text_color_hover;
  styles_obj["--footer_logo"] = `url(${styles.footer_logo})`;
  styles_obj["--footer_logo_width"] = styles.footer_logo_width;
  styles_obj["--footer_logo_height"] = styles.footer_logo_height;
  if (styles.footer_logo_active === 'true') {
    styles_obj["--footer_logo_active"] = 'flex';
  } else {
    styles_obj["--footer_logo_active"] = 'none';
  }

  // widget clender
  styles_obj["--date-picker-color-date-time"] = styles.brand_color_3;
  styles_obj["--date-picker-color-button"] = styles.brand_color_3;

  // results (apply to all result pages)
  styles_obj["--result_title_font_family"] = styles.result_title_font_family;
  styles_obj["--result_title_font_size"] = styles.result_title_font_size;
  styles_obj["--result_title_font_weight"] = styles.result_title_font_weight;
  styles_obj["--result_title_font_color"] = styles.result_title_font_color;
  styles_obj["--result_tagline_font_family"] = styles.result_tagline_font_family;
  styles_obj["--result_tagline_font_size"] = styles.result_tagline_font_size;
  styles_obj["--result_tagline_font_weight"] = styles.result_tagline_font_weight;
  styles_obj["--result_tagline_font_color"] = styles.result_tagline_font_color;
  styles_obj["--result_time_dist_font_family"] = styles.result_time_dist_font_family;
  styles_obj["--result_time_dist_font_size"] = styles.result_time_dist_font_size;
  styles_obj["--result_time_dist_font_weight"] = styles.result_time_dist_font_weight;
  styles_obj["--result_time_dist_font_color"] = styles.result_time_dist_font_color;
  styles_obj["--result_time_dist_subtext_font_family"] = styles.result_time_dist_subtext_font_family;
  styles_obj["--result_time_dist_subtext_font_size"] = styles.result_time_dist_subtext_font_size;
  styles_obj["--result_time_dist_subtext_font_weight"] = styles.result_time_dist_subtext_font_weight;
  styles_obj["--result_time_dist_subtext_font_color"] = styles.result_time_dist_subtext_font_color;
  styles_obj["--result_price_font_family"] = styles.result_price_font_family;
  styles_obj["--result_price_font_size"] = styles.result_price_font_size;
  styles_obj["--result_price_font_weight"] = styles.result_price_font_weight;
  styles_obj["--result_price_font_color"] = styles.result_price_font_color;
  styles_obj["--result_price_subtext_font_family"] = styles.result_price_subtext_font_family;
  styles_obj["--result_price_subtext_font_size"] = styles.result_price_subtext_font_size;
  styles_obj["--result_price_subtext_font_weight"] = styles.result_price_subtext_font_weight;
  styles_obj["--result_price_subtext_font_color"] = styles.result_price_subtext_font_color;
  styles_obj["--result_map_info_font_family"] = styles.result_map_info_font_family;
  styles_obj["--result_map_info_font_size"] = styles.result_map_info_font_size;
  styles_obj["--result_map_info_font_weight"] = styles.result_map_info_font_weight;
  styles_obj["--result_map_info_font_color"] = styles.result_map_info_font_color;
  styles_obj["--result_map_info_svg_color"] = styles.result_map_info_svg_color;
  styles_obj["--result_button_primary_font_family"] = styles.result_button_primary_font_family;
  styles_obj["--result_button_primary_font_size"] = styles.result_button_primary_font_size;
  styles_obj["--result_button_primary_font_weight"] = styles.result_button_primary_font_weight;
  styles_obj["--result_button_primary_font_color"] = styles.result_button_primary_font_color;
  styles_obj["--result_button_primary_background"] = styles.result_button_primary_background;
  styles_obj["--result_button_primary_border"] = styles.result_button_primary_border;
  styles_obj["--result_button_primary_border_radius"] = styles.result_button_primary_border_radius;
  styles_obj["--result_button_secondary_font_family"] = styles.result_button_secondary_font_family;
  styles_obj["--result_button_secondary_font_size"] = styles.result_button_secondary_font_size;
  styles_obj["--result_button_secondary_font_weight"] = styles.result_button_secondary_font_weight;
  styles_obj["--result_button_secondary_font_color"] = styles.result_button_secondary_font_color;
  styles_obj["--result_button_secondary_background"] = styles.result_button_secondary_background;
  styles_obj["--result_button_secondary_border"] = styles.result_button_secondary_border;
  styles_obj["--result_button_secondary_border_radius"] = styles.result_button_secondary_border_radius;
  styles_obj["--results_sort_filters"] = styles.results_sort_filters;
  if (styles.results_details_as_list === 'list') {
    styles_obj["--details_as_paragraph"] = 'none';
  } else {
    styles_obj["--details_as_list"] = 'none';
  }


  // your details

  styles_obj["--details_headings_font_family"] = styles.details_headings_font_family;
  styles_obj["--details_headings_font_size"] = styles.details_headings_font_size;
  styles_obj["--details_headings_font_weight"] = styles.details_headings_font_weight;
  styles_obj["--details_headings_font_color"] = styles.details_headings_font_color;
  styles_obj["--details_subheadings_font_family"] = styles.details_subheadings_font_family;
  styles_obj["--details_subheadings_font_size"] = styles.details_subheadings_font_size;
  styles_obj["--details_subheadings_font_weight"] = styles.details_subheadings_font_weight;
  styles_obj["--details_subheadings_font_color"] = styles.details_subheadings_font_color;
  styles_obj["--details_text_font_family"] = styles.details_text_font_family;
  styles_obj["--details_text_font_size"] = styles.details_text_font_size;
  styles_obj["--details_text_font_weight"] = styles.details_text_font_weight;
  styles_obj["--details_text_font_color"] = styles.details_text_font_color;
  styles_obj["--details_inputs_font_family"] = styles.details_inputs_font_family;
  styles_obj["--details_inputs_font_size"] = styles.details_inputs_font_size;
  styles_obj["--details_inputs_font_weight"] = styles.details_inputs_font_weight;
  styles_obj["--details_inputs_font_color"] = styles.details_inputs_font_color;
  styles_obj["--details_buttons_primary_font_family"] = styles.details_buttons_primary_font_family;
  styles_obj["--details_buttons_primary_font_size"] = styles.details_buttons_primary_font_size;
  styles_obj["--details_buttons_primary_font_weight"] = styles.details_buttons_primary_font_weight;
  styles_obj["--details_buttons_primary_font_color"] = styles.details_buttons_primary_font_color;
  styles_obj["--details_buttons_primary_background"] = styles.details_buttons_primary_background;
  styles_obj["--details_buttons_primary_border"] = styles.details_buttons_primary_border;
  styles_obj["--details_buttons_primary_border_radius"] = styles.details_buttons_primary_border_radius;
  styles_obj["--details_buttons_secondary_font_family"] = styles.details_buttons_secondary_font_family;
  styles_obj["--details_buttons_secondary_font_size"] = styles.details_buttons_secondary_font_size;
  styles_obj["--details_buttons_secondary_font_weight"] = styles.details_buttons_secondary_font_weight;
  styles_obj["--details_buttons_secondary_font_color"] = styles.details_buttons_secondary_font_color;
  styles_obj["--details_buttons_secondary_background"] = styles.details_buttons_secondary_background;
  styles_obj["--details_buttons_secondary_border"] = styles.details_buttons_secondary_border;
  styles_obj["--details_buttons_secondary_border_radius"] = styles.details_buttons_secondary_border_radius;
  styles_obj["--details_infoboxes_font_family"] = styles.details_infoboxes_font_family;
  styles_obj["--details_infoboxes_font_size"] = styles.details_infoboxes_font_size;
  styles_obj["--details_infoboxes_font_weight"] = styles.details_infoboxes_font_weight;
  styles_obj["--details_infoboxes_font_color"] = styles.details_infoboxes_font_color;
  styles_obj["--details_infoboxes_background"] = styles.details_infoboxes_background;
  styles_obj["--details_infoboxes_border_radius"] = styles.details_infoboxes_border_radius;
  // confirmation
  styles_obj["--details_buttons_confirmation_font_family"] = styles.details_buttons_confirmation_font_family;
  styles_obj["--details_buttons_confirmation_font_size"] = styles.details_buttons_confirmation_font_size;
  styles_obj["--details_buttons_confirmation_font_weight"] = styles.details_buttons_confirmation_font_weight;
  styles_obj["--details_buttons_confirmation_font_color"] = styles.details_buttons_confirmation_font_color;
  styles_obj["--details_buttons_confirmation_background"] = styles.details_buttons_confirmation_background;
  styles_obj["--details_buttons_confirmation_border"] = styles.details_buttons_confirmation_border;
  styles_obj["--details_buttons_confirmation_border_radius"] = styles.details_buttons_confirmation_border_radius;
  styles_obj["--details_numbers_confirmation_font_family"] = styles.details_numbers_confirmation_font_family;
  styles_obj["--details_numbers_confirmation_font_size"] = styles.details_numbers_confirmation_font_size;
  styles_obj["--details_numbers_confirmation_font_weight"] = styles.details_numbers_confirmation_font_weight;
  styles_obj["--details_numbers_confirmation_font_color"] = styles.details_numbers_confirmation_font_color;
  styles_obj["--details_numbers_confirmation_background"] = styles.details_numbers_confirmation_background;
  styles_obj["--details_numbers_confirmation_border"] = styles.details_numbers_confirmation_border;

  // basket styles
  styles_obj["--basket_price_font_family"] = styles.basket_price_font_family;
  styles_obj["--basket_price_font_weight"] = styles.basket_price_font_weight;
  styles_obj["--basket_price_font_color"] = styles.basket_price_font_color;
  styles_obj["--basket_promo_input_font_family"] = styles.basket_promo_input_font_family;
  styles_obj["--basket_promo_input_font_size"] = styles.basket_promo_input_font_size;
  styles_obj["--basket_promo_input_font_weight"] = styles.basket_promo_input_font_weight;
  styles_obj["--basket_promo_input_font_color"] = styles.basket_promo_input_font_color;
  styles_obj["--basket_promo_button_font_family"] = styles.basket_promo_button_font_family;
  styles_obj["--basket_promo_button_font_size"] = styles.basket_promo_button_font_size;
  styles_obj["--basket_promo_button_font_weight"] = styles.basket_promo_button_font_weight;
  styles_obj["--basket_promo_button_font_color"] = styles.basket_promo_button_font_color;
  styles_obj["--basket_promo_button_background"] = styles.basket_promo_button_background;
  styles_obj["--basket_promo_button_border"] = styles.basket_promo_button_border;
  styles_obj["--basket_promo_button_border_radius"] = styles.basket_promo_button_border_radius;

  // mmb styles
  styles_obj["--mmb_login_button_font_family"] = styles.mmb_login_button_font_family;
  styles_obj["--mmb_login_button_font_size"] = styles.mmb_login_button_font_size;
  styles_obj["--mmb_login_button_font_weight"] = styles.mmb_login_button_font_weight;
  styles_obj["--mmb_login_button_font_color"] = styles.mmb_login_button_font_color;
  styles_obj["--mmb_login_button_background"] = styles.mmb_login_button_background;
  styles_obj["--mmb_login_button_border"] = styles.mmb_login_button_border;
  styles_obj["--mmb_login_button_border_radius"] = styles.mmb_login_button_border_radius;
  styles_obj["--mmb_manage_button_font_family"] = styles.mmb_manage_button_font_family;
  styles_obj["--mmb_manage_button_font_size"] = styles.mmb_manage_button_font_size;
  styles_obj["--mmb_manage_button_font_weight"] = styles.mmb_manage_button_font_weight;
  styles_obj["--mmb_manage_button_font_color"] = styles.mmb_manage_button_font_color;
  styles_obj["--mmb_manage_button_background"] = styles.mmb_manage_button_background;
  styles_obj["--mmb_manage_button_border"] = styles.mmb_manage_button_border;
  styles_obj["--mmb_manage_button_border_radius"] = styles.mmb_manage_button_border_radius;
  styles_obj["--mmb_booking_details_button_font_family"] = styles.mmb_booking_details_button_font_family;
  styles_obj["--mmb_booking_details_button_font_size"] = styles.mmb_booking_details_button_font_size;
  styles_obj["--mmb_booking_details_button_font_weight"] = styles.mmb_booking_details_button_font_weight;
  styles_obj["--mmb_booking_details_button_font_color"] = styles.mmb_booking_details_button_font_color;
  styles_obj["--mmb_booking_details_button_background"] = styles.mmb_booking_details_button_background;
  styles_obj["--mmb_booking_details_button_border"] = styles.mmb_booking_details_button_border;
  styles_obj["--mmb_booking_details_button_border_radius"] = styles.mmb_booking_details_button_border_radius;
  styles_obj["--mmb_booking_change_button_font_family"] = styles.mmb_booking_change_button_font_family;
  styles_obj["--mmb_booking_change_button_font_size"] = styles.mmb_booking_change_button_font_size;
  styles_obj["--mmb_booking_change_button_font_weight"] = styles.mmb_booking_change_button_font_weight;
  styles_obj["--mmb_booking_change_button_font_color"] = styles.mmb_booking_change_button_font_color;
  styles_obj["--mmb_booking_change_button_background"] = styles.mmb_booking_change_button_background;
  styles_obj["--mmb_booking_change_button_border"] = styles.mmb_booking_change_button_border;
  styles_obj["--mmb_booking_change_button_border_radius"] = styles.mmb_booking_change_button_border_radius;

  console.log('styles_obj', styles_obj);
  styles_obj.data_loaded = true;
  return styles_obj;
};



// export
if (typeof exports != "undefined") {
  exports.set_style_vars = set_style_vars;
} else {
  window.set_style_vars = set_style_vars;
}