var v5_golive_dates = function(slug) {
  const GOLIVE_DATES = {
    NQY: '23/11/2023',
    INV: '20/02/2024',
    IP1: '29/02/2024',
    NOC: '04/04/2024',
    LDY: '09/05/2024',
  };
  const bslug = slug.toUpperCase();
  if (!Object.keys(GOLIVE_DATES).includes(bslug)) {
    return 'THE_FUTURE';
  }
  return GOLIVE_DATES[bslug];
};
if (typeof exports != "undefined") {
  exports.v5_golive_dates = v5_golive_dates;
} else {
  window.v5_golive_dates = v5_golive_dates;
}
