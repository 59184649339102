import axios from "axios";
import {
  SLUG_API
} from "../../config/api";

const state = {
  slug: "",
  currency: "",
  siteName: ""
};

const getters = {
  getSlug: (state) => state.slug,
  getCurrency: (state) => state.currency,
  getSiteName: (state) => state.siteName,
};

const actions = {
  setSlug({
    commit
  }) {
    return axios
      .post(SLUG_API, "", {
        headers: {
          secret_id: "4cf6550ce68e1bc52918d8f81c6af23e",
          secret_key: "6e3fa29353ece4927ae3e3748b250f09a159cc2993e7c730",
        },
      })
      .then(
        (response) => {
          let dataSent = JSON.parse(response.request.response);
          if (dataSent.code == "1") {
            commit("setNewSlug", dataSent.slug);
            if (dataSent.slug == "HER" || dataSent.slug == "NOC" || dataSent.slug == "FM1") {
              dataSent.currency = "€";
            }
            commit("setNewCurrency", dataSent.currency);
            commit("setNewSiteName", dataSent.sitename);
            return dataSent.slug;
          } else {
            console.log(dataSent.slug);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  },
};

const mutations = {
  setNewSlug: (state, slug) => (state.slug = slug),
  setNewCurrency: (state, currency) => (state.currency = currency),
  setNewSiteName: (state, siteName) => (state.siteName = siteName),
};

export default {
  state,
  getters,
  actions,
  mutations,
};