import axios from 'axios';
let url = '/data/bookingData.json';

export default class BookingService {

    getBookingsSmall() {
        return axios.get(url).then(res => res.data);
    }

    getBookingsMedium() {
        return axios.get(url).then(res => res.data);
    }

    getBookingsLarge(datasearch) {
        return axios.post('https://devapi-frankfurt.rezcomm.com/dev_customer_orders/',datasearch).then(res => res.data);
    }

    getBookingsXLarge() {
        return axios.get(url).then(res => res.data);
    }

    getBookings(params) {
        return axios.get(url).then(res => res.data);
    }
}

